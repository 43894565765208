import { ReactNode } from 'react'
import { animated } from 'react-spring'
import { OverflowProperty, AlignSelfProperty } from 'csstype'
import { spacingLevel, Responsive, makeResponsive } from '../layout'
import { styled } from '../styled'
import { Image } from './image'
import { Base, BaseProps } from './responsive'

export interface BoxProps extends BaseProps {
  children?: ReactNode
  provide?: boolean
  position?: string
  padding?: Responsive<number>
  margin?: Responsive<number>
  marginRight?: Responsive<number>
  marginBottom?: Responsive<number>
  marginTop?: Responsive<number>
  marginVertical?: Responsive<number>
  marginHorizontal?: Responsive<number>
  paddingVertical?: Responsive<number>
  paddingHorizontal?: Responsive<number>
  paddingBottom?: Responsive<number>
  paddingLeft?: number
  paddingTop?: number
  width?: Responsive<number | string>
  height?: Responsive<number | string>
  minWidth?: Responsive<number | string>
  maxWidth?: Responsive<number | string>
  minHeight?: number | string
  maxHeight?: number | string
  pointerEvents?: string
  userSelect?: string
  overflowChildren?: boolean
  flex?: number
  flexBasis?: number | string
  flexGrow?: Responsive<number>
  flexShrink?: number
  offsetX?: number
  offsetY?: number
  top?: number
  left?: number
  right?: number
  bottom?: Responsive<number>
  transform?: Responsive<string>
  overflow?: OverflowProperty
  overflowX?: OverflowProperty
  z?: number
  shadow?: string
  bg?: string | Image
  alignSelf?: Responsive<AlignSelfProperty>
  color?: string
  parallaxDistance?: number
  border?: string
  borderRadius?: string | number
}

const responsive = makeResponsive<BoxProps>()

export const Box = styled(Base.withComponent(animated.div))<BoxProps>`
  ${responsive('padding', (padding = 0) => `${spacingLevel(padding)}px`)};
  ${responsive('margin', (margin) => margin && `${spacingLevel(margin)}px`)};
  ${responsive(
    'marginHorizontal',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'margin-left',
  )};
  ${responsive(
    'marginHorizontal',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'margin-right',
  )};
  ${responsive(
    'marginVertical',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'margin-top',
  )};
  ${responsive(
    'marginVertical',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'margin-bottom',
  )};
  ${responsive(
    'marginTop',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'margin-top',
  )};
  ${responsive(
    'marginBottom',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'margin-bottom',
  )};
  ${responsive(
    'marginRight',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'margin-right',
  )};
  ${responsive(
    'paddingHorizontal',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-left',
  )};
  ${responsive(
    'paddingHorizontal',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-right',
  )};
  ${responsive(
    'paddingVertical',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-top',
  )};
  ${responsive(
    'paddingVertical',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-bottom',
  )};
  ${responsive(
    'paddingBottom',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-bottom',
  )};
  ${responsive(
    'paddingTop',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-top',
  )};
  ${({ paddingLeft }) =>
    paddingLeft && `padding-left: ${spacingLevel(paddingLeft)}px`};
  ${responsive('height')};
  ${responsive('width')};
  ${responsive('transform')};
  ${responsive('alignSelf', undefined, 'align-self')};
  box-sizing: border-box;
  position: ${({ position = 'relative' }) => position};
  pointer-events: ${({ pointerEvents }) => pointerEvents};
  pointer-events: ${({ pointerEvents }) => pointerEvents};
  flex: ${({ flex }) => flex};
  flex-basis: ${({ flexBasis }) => flexBasis};
  ${responsive('flexGrow', (flexGrow) => flexGrow, 'flex-grow')};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  left: ${({ left, offsetX = left }) => offsetX && spacingLevel(offsetX)}px;
  top: ${({ top, offsetY = top }) => offsetY && spacingLevel(offsetY)}px;
  right: ${({ right }) => right && spacingLevel(right)}px;
  ${responsive('bottom', (bottom) => bottom && spacingLevel(bottom) + 'px')};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};

  ${({ bg = 'rgba(0,0,0,0)', theme }) => {
    if (!bg) {
      return undefined
    }

    if (typeof bg === 'string') {
      return `
        background-color: ${theme.color(bg)};
      `
    }

    return `
      background-image: url('${bg.url}');
      background-size: ${bg.objectFit || 'cover'};
      background-position: ${bg.objectPosition || 'center'};
    `
  }}

  box-shadow: ${({ shadow }) => shadow && shadow};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  ${responsive('minWidth', (minWidth) => String(minWidth), 'min-width')};
  ${responsive('maxWidth', (maxWidth) => String(maxWidth), 'max-width')};
  border-radius: ${({ borderRadius }) => borderRadius};
  user-select: ${({ userSelect }) => userSelect};
  color: ${({ color, theme }) => theme.color(color)};
  z-index: ${({ z }) => z};
  ${responsive(
    'paddingHorizontal',
    (padding) => padding && `${spacingLevel(padding)}px`,
    'padding-left',
  )};
  ${responsive('transform')};
  ${responsive('border')};

  > * {
    ${({ overflowChildren }) => (!overflowChildren ? 'max-width: 100%' : '')};
  }
`
