import { memoize } from 'lodash'
import { useEffect } from 'react'

const setup = memoize(() => {
  const style = document.createElement('style')
  style.innerHTML = `
    html.scrollLock,
    html.scrollLock #___gatsby > * {
      max-height: 100%;
      overflow-y: hidden;
    }
  `
  document.head.appendChild(style)
})

export const useScrollLock = (locked: boolean = true) => {
  useEffect(() => {
    setup()

    if (locked) {
      document.documentElement.classList.add('scrollLock')
    } else {
      document.documentElement.classList.remove('scrollLock')
    }

    return () => {
      document.documentElement.classList.remove('scrollLock')
    }
  }, [locked])
}
