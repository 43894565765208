import React, { ReactElement, cloneElement } from 'react'
import { useTransition } from 'react-spring'
import { Box, BoxProps } from '../atoms'
import { styled } from '../styled'
import { usePrevious } from 'react-use'

interface CarouselProps extends BoxProps {
  children: ReactElement<BoxProps>[]
  index?: number
}

const CarouselWrapper = styled(Box)`
  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    will-change: transform, opacity;
  }
`

export const Carousel = ({ children, index = 0, ...props }: CarouselProps) => {
  const prev = usePrevious(index)
  const transitions = useTransition(index, p => p, {
    from: {
      opacity: 0,
      transform:
        prev < index ? 'translate3d(100%,0,0)' : 'translate3d(-50%,0,0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform:
        prev < index ? 'translate3d(-50%,0,0)' : 'translate3d(100%,0,0)',
    },
  })

  return (
    <CarouselWrapper {...props}>
      {transitions.map(({ item, props, key }) => {
        return cloneElement(children[mod(item, children.length)], {
          style: props,
          key,
        })
      })}
    </CarouselWrapper>
  )
}

function mod(n, m) {
  return ((n % m) + m) % m
}
