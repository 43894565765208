import { format, utcToZonedTime } from 'date-fns-tz'

export function tuple<T extends unknown[]>(...xs: T): T {
  return xs
}

export const plural = (
  arr: string[],
  and: boolean | string = false,
  delimiter = ', ',
): string => {
  if (!and || arr.length <= 1) return arr.join(delimiter)
  const last = arr.pop()
  return `${[...arr].join(delimiter)} ${and !== true ? and : 'true'} ${last}`
}

export const arrayify = <T>(x: T | undefined | null | T[]) => {
  if (!x) {
    return []
  }
  if (Array.isArray(x)) {
    return x
  }
  return [x]
}

export const formatFormation = (
  formation: 'in_person' | 'hybrid' | 'online',
) => {
  switch (formation) {
    case 'in_person':
      return 'In Person'
    case 'hybrid':
      return 'In Person or Online'
    case 'online':
      return 'Online'
  }
}

export const formatTime = (
  time: string | Date,
  timeZone?: string | undefined,
) => {
  const dt = applyTz(hackFixStupidDjangoDate(time), timeZone)

  if (dt.getMinutes() === 0) {
    return format(dt, 'hbbb').toLowerCase()
  }

  return format(dt, 'h:mma').toLowerCase()
}

export const formatDate = (
  date: string | Date,
  timeZone?: string | undefined,
  isShort?: boolean,
) => {
  return format(
    applyTz(hackFixStupidDjangoDate(date), timeZone),
    isShort ? 'dd.MM' : 'eeee do LLLL',
    {
      timeZone,
    },
  )
}

export const formatTimezone = (
  date: string | Date,
  timeZone: string | undefined,
  template = 'zzz',
) => {
  return format(applyTz(hackFixStupidDjangoDate(date), timeZone), template, {
    timeZone,
  })
}

export const getUserTimezone = () => {
  if (typeof window === 'undefined') {
    return undefined
  }

  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch {
    return undefined
  }
}

export const hackFixStupidDjangoDate = (dateStr: string | Date) =>
  typeof dateStr === 'string'
    ? new Date(dateStr.replace(' ', 'T'))
    : new Date(dateStr)

const applyTz = (date: Date, timezone?: string | undefined) => {
  if (timezone) {
    return utcToZonedTime(date, timezone)
  }

  return date
}

export const formatToColor = (format: string | undefined) => {
  switch (format) {
    case 'Debate':
      return 'pink'
    case 'Forum':
      return 'orange'
    case 'Workshop':
      return 'blue'
    case 'Panel':
      return 'darkPink'
    case 'Participatory':
      return 'purple'
    case 'Discussion':
      return 'yellow'
    default:
      return 'navy'
  }
}
