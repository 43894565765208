import { styled } from '../styled'
import { Breakpoint, screens } from '../layout'
import { HTMLAttributes } from 'react'

export interface BaseProps extends HTMLAttributes<{}> {
  showFrom?: Breakpoint
  showUntil?: Breakpoint
}

export const Base = styled.span<BaseProps>`
  ${({ showFrom: minScreen }) =>
    minScreen
      ? `@media (max-width: ${screens[minScreen] - 0.5}px) { display: none }`
      : ''};
  ${({ showUntil: maxScreen }) =>
    maxScreen
      ? `@media (min-width: ${screens[maxScreen]}px) { display: none }`
      : ''};
`
