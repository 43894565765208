import { styled } from '../styled'
import { Box } from './box'

export const IconButton = styled(Box.withComponent('button'))`
  cursor: pointer;
  color: inherit;
  text-align: inherit;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  margin: 0;
`

export const PlainButton = styled(Box.withComponent('button'))`
  cursor: pointer;
  text-align: inherit;
  border: none;
  outline: none;
  border: none;
  margin: 0;
  border-radius: 2px;
  &:hover {
    opacity: 0.8;
  }
`
