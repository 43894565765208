import React from 'react'
import { SvgGraphic, SvgGraphicProps } from '../atoms'

export const FacebookIcon = (props: SvgGraphicProps) => (
  <SvgGraphic viewBox="0 0 24 24" width="36" height="36" {...props}>
    <path
      d="M24 12a12 12 0 1 0-13.9 11.9v-8.4h-3V12h3V9.4c0-3 1.8-4.7 4.6-4.7l2.6.2v3h-1.5c-1.5 0-2 .9-2 1.8V12h3.4l-.5 3.5h-2.8v8.4A12 12 0 0 0 24 12z"
      fill="currentColor"
    />
  </SvgGraphic>
)

export const TwitterIcon = (props: SvgGraphicProps) => (
  <SvgGraphic viewBox="0 0 24 24" width="36" height="36" {...props}>
    <path
      d="M24 4.6c-1 .4-1.9.6-2.9.7a5 5 0 0 0 2.2-2.7c-1 .6-2 1-3.1 1.2-1-1-2.2-1.6-3.6-1.6a5 5 0 0 0-4.8 6 14 14 0 0 1-10.2-5c-.4.7-.6 1.5-.6 2.4a5 5 0 0 0 2.2 4.1c-.8 0-1.6-.2-2.3-.6A5 5 0 0 0 5 14a5 5 0 0 1-2.2 0 5 5 0 0 0 4.6 3.5 9.9 9.9 0 0 1-7.3 2 14 14 0 0 0 7.6 2.3c9 0 14-7.5 14-14V7A10 10 0 0 0 24 4.6z"
      fill="currentColor"
    />
  </SvgGraphic>
)

export const EmailIcon = (props: SvgGraphicProps) => (
  <SvgGraphic viewBox="0 0 60 48" {...props}>
    <path
      d="M54 0H6C2.7 0 .03 2.7.03 6L0 42c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6zm0 12L30 27 6 12V6l24 15L54 6v6z"
      fill="currentColor"
    />
  </SvgGraphic>
)
