import React, { useEffect, useState, ReactElement, FC, Fragment } from 'react'

interface NoSSRProps {
  fallback?: ReactElement
}

export const NoSSR: FC<NoSSRProps> = ({
  fallback = <Fragment />,
  children,
}) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted ? <Fragment>{children}</Fragment> : fallback
}
