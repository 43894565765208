import React, { useRef, useEffect } from 'react'
import youtube from 'youtube-player'
import { styled } from '../styled'
import { Box, BoxProps } from '../atoms'
import { YouTubePlayer } from 'youtube-player/dist/types'
import { useScrollSpy } from '../../services'

interface VideoEmbedProps extends BoxProps {
  video?: string
}

const DesktopIframe = styled.iframe`
  margin: -64px 0;
  width: 100%;
  height: calc(100% + 128px);
  overflow: hidden;
  pointer-events: none;
`

const MobileIframe = styled.iframe`
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const origin = encodeURIComponent(
  typeof window === 'undefined'
    ? 'https://theworldtransformed.org'
    : window.location.origin
)

export const VideoEmbed = ({ video, ...props }: VideoEmbedProps) => {
  const ref = useRef<HTMLIFrameElement>()
  const ytRef = useRef<YouTubePlayer>()

  useEffect(() => {
    if (ref.current) {
      ytRef.current = youtube(ref.current)
    }
  }, [])

  const onscreen = useScrollSpy({
    refs: [ref],
    container: 'html',
    errorMargin: 100,
  })

  useEffect(() => {
    if (!ytRef.current) {
      return
    }

    if (onscreen) {
      ytRef.current.playVideo()
    } else {
      ytRef.current.pauseVideo()
    }
  }, [onscreen, ytRef])

  const srcStr = `https://www.youtube.com/embed/${video}?loop=1&autoplay=1&playlist=${video}&iv_load_policy=3&modestbranding=1&controls=0&enablejsapi=1&wmode=opaque&branding=0&autohide=0&mute=true&rel=0&start=9&origin=${origin}`

  if (typeof window === 'undefined') {
    return <DesktopIframe frameBorder="0" src={srcStr} />
  }

  return (
    <Box {...props} overflow="hidden">
      <DesktopIframe frameBorder="0" ref={ref} src={srcStr} />
    </Box>
  )
}
