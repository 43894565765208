import { styled } from '../styled'
import { Box } from './box'
import { spacingLevel } from '../layout'

export const Input = styled(Box.withComponent('input'))`
  ${({ theme }) => theme.font('regular')}

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: ${spacingLevel(1)}px 0px;
  background: rgba(0, 0, 0, 0);
  margin: 0;
  border: none;
  outline: none;
`
