import { ValueOf, Primitive } from 'ts-essentials'
import { memoize, mapValues } from 'lodash'
import { Interpolation } from '@emotion/core'

export const screens = {
  mobile: 0,
  mobileLarge: 540,
  tablet: 768,
  desktop: 972,
  desktopLarge: 1195,
}

type UnboxResponsive<R> = R extends Record<number, unknown> ? ValueOf<R> : R
type Screens = typeof screens

export type Breakpoint = keyof Screens
export type Responsive<T extends Primitive = Primitive> =
  | T
  | Partial<Record<Breakpoint, T>>

export const makeResponsive = <Props>() => {
  return <Key extends keyof Props>(
    property: Key,
    fn: (val: UnboxResponsive<Props[Key]>, rest: Props) => string = String,
    cssProperty: string = String(property),
  ) => (props: Props) => {
    const val = props[property]

    if (val && typeof val === 'object') {
      return Object.entries(val)
        .map(([breakpoint, val]) => {
          return `@media (min-width: ${
            screens[breakpoint as Breakpoint]
          }px) { ${cssProperty}: ${fn(val, props)} }`
        })
        .join('\n')
    }

    return `${cssProperty}: ${fn(val as UnboxResponsive<Props[Key]>, props)}`
  }
}

export const responsive = (
  props: Partial<Record<Breakpoint, Interpolation>>,
) => {
  const res: Record<string, Interpolation> = {}
  for (const [op, size] of Object.entries(screens)) {
    if (op in props) {
      res[`@media (min-width: ${size}px)`] = props[op]
    }
  }
  return res
}

export const mapResponsive = <T extends Primitive, U extends Primitive>(
  val: Responsive<T>,
  fn: (x: T) => U,
): Responsive<U> => {
  if (val && typeof val === 'object') {
    return mapValues(val, fn)
  }

  return fn(val as T)
}

const fib = memoize((x: number): number => (x <= 1 ? x : fib(x - 1) + x))
const spacingUnit = 6

export const spacingLevel = (x: number) => {
  if (x < 0) {
    return fib(-x) * -spacingUnit
  }

  return fib(x) * spacingUnit
}
