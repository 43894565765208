import { Field } from 'react-jeff'
import React, {
  memo,
  ComponentType,
  ReactNode,
  useCallback,
  createContext,
  useContext,
  useState,
  FormEvent,
  ReactElement,
  ButtonHTMLAttributes,
  cloneElement,
  PropsWithChildren,
} from 'react'
import { identity } from 'lodash'
import { BoxProps, Input, Rows, FlexProps } from '../atoms'

interface FieldProps extends BoxProps {
  field?: Field<string>
  autoComplete?: string
  valueType?: Converter
  wrapper: ComponentType<{ disabled?: boolean }>
  prefixView?: ReactNode
  suffix?: ReactNode
  type?: string
  length?: number
  disabled?: boolean
  width?: string
}

interface Converter {
  toString: (x: any) => string
  fromString: (x: string) => any
}

export const NumberFieldType: Converter = {
  toString: String,
  fromString: Number,
}

const IdentityFieldType: Converter = {
  toString: identity,
  fromString: identity,
}

const FormContext = createContext<FormContext | undefined>(undefined)

export const useFormState = () => useContext(FormContext) || { pending: false }

export const FormField = memo(
  ({
    field,
    type,
    wrapper: Wrapper,
    valueType = IdentityFieldType,
    prefix,
    suffix,
    length,
    disabled,
    children,
    ...props
  }: FieldProps) => {
    const state = useFormState()
    const { onChange, ...inputProps } = field.props

    return (
      <Wrapper disabled={disabled || state.pending}>
        {prefix}
        <Input
          {...inputProps}
          {...props}
          value={valueType.toString(inputProps.value)}
          onChange={(x) => inputProps.onChange(valueType.fromString(x))}
          disabled={disabled || state.pending}
          width={length && length + 'em'}
          type={type}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        {suffix}
      </Wrapper>
    )
  },
)

interface FormProps extends FlexProps {
  onSubmit: () => unknown
  defaultErrorMessage?: string
  children?: ReactNode
}

interface FormContext {
  error?: string
  pending: boolean
}

export class FormError implements Error {
  public constructor(readonly message: string) {
    Object.assign(this, { message })
  }

  public get name() {
    return 'FormError'
  }
}

const FormWrapper = Rows.withComponent('form')

export const Form = ({
  onSubmit,
  defaultErrorMessage: defaultMessage = 'Sorry, something went wrong',
  ...props
}: FormProps) => {
  const [state, setState] = useState<FormContext>(
    useContext(FormContext) || { pending: false },
  )

  const handleSubmit = useCallback(
    async (e: FormEvent<{}>) => {
      try {
        e.preventDefault()
        if (state.pending) {
          return
        }

        setState({ pending: true, error: undefined })
        await onSubmit()
        setState({ pending: false })
      } catch (error) {
        if (error) {
          console.error(error)
        }

        setState({
          pending: false,
          error: error instanceof FormError ? error.message : defaultMessage,
        })
      }
    },
    [onSubmit],
  )

  return (
    <FormContext.Provider value={state}>
      <FormWrapper onSubmit={handleSubmit} {...props} />
    </FormContext.Provider>
  )
}

interface FormSubmitProps {
  placeholder?: ReactNode
  children: ReactElement<ButtonHTMLAttributes<{}>>
}

export const FormSubmit = ({
  children,
  placeholder = 'Submitting…',
}: FormSubmitProps) => {
  const state = useFormState()

  if (state.pending) {
    return cloneElement(
      children,
      { disabled: children.props.disabled || state.pending },
      placeholder,
    )
  }

  return children
}

interface FormErrorDisplayProps {
  children: ReactElement
}

export const FormErrorDisplay = ({ children }: FormErrorDisplayProps) => {
  const state = useFormState()

  if (state.error) {
    return cloneElement(children, {}, state.error)
  }

  return null
}

export const InjectFormState = ({
  children,
  ...context
}: PropsWithChildren<FormContext>) => (
  <FormContext.Provider value={context}>{children}</FormContext.Provider>
)
