import React, { ComponentType, SVGProps } from 'react'
import { animated } from 'react-spring'
import { Responsive, mapResponsive } from '../layout'
import { Box, BoxProps } from './box'

export interface SvgGraphicProps
  extends Omit<SvgProps, 'width' | 'height' | 'scale'> {
  scale?: Responsive<number>
  width?: Responsive<string | number>
  height?: Responsive<string | number>
}

interface SvgProps
  extends BoxProps,
    Omit<SVGProps<SVGElement>, keyof BoxProps> {}

const Svg = Box.withComponent(animated.svg) as ComponentType<SvgProps>

const getDimensions = (vb: string) =>
  vb
    .split(' ')
    .slice(2)
    .map(Number)

export const SvgGraphic = ({
  scale = 1,
  ...props
}: SvgGraphicProps & { viewBox: string }) => {
  const [w, h] = getDimensions(props.viewBox)
  return (
    <Svg
      width={scale ? mapResponsive(scale, s => w * s + 'px') : undefined}
      height={scale ? mapResponsive(scale, s => h * s + 'px') : undefined}
      {...props}
    />
  )
}
