import React from 'react'
import { SvgGraphicProps, SvgGraphic, Themed } from '../../design-system'
import { withTheme } from 'emotion-theming'

export const BurgerGraphic = withTheme(
  ({ fill, theme, ...props }: Themed<SvgGraphicProps>) => (
    <SvgGraphic viewBox="0 0 49 34" fill="none" {...props}>
      <path
        d="M49 33H0m49-16H0M49 1H0"
        stroke={(fill && theme.color(fill)) || 'currentColor'}
        strokeWidth={2}
      />
    </SvgGraphic>
  )
)
