import React from 'react'
import { SvgGraphicProps, SvgGraphic } from '../atoms'

export const CloseIcon = (props: SvgGraphicProps) => (
  <SvgGraphic
    width={16}
    height={16}
    viewBox="0 0 357 357"
    fill="none"
    {...props}
  >
    <path
      d="M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z"
      fill="currentColor"
    />
  </SvgGraphic>
)
