import { Field, useField, useForm } from 'react-jeff'
import { omit, mapValues } from 'lodash'
import { ObjectSchema } from 'yup'
import { useEffect } from 'react'

export type ToggleSet = Record<string, true>

const set = (set: ToggleSet, key: string, value: boolean): ToggleSet => {
  return value ? { ...set, [key]: true } : omit(set, key)
}

export const useFormToggleSet = (field: Field<ToggleSet>) => {
  return {
    value: field.value,
    toggleHandler: (key: string) => () =>
      field.props.onChange(set(field.value, key, !field.value[key])),
  }
}

interface UseFormSchemaProps<T extends object> {
  schema: ObjectSchema<T>
  initialData: unknown
  defaultValues?: Partial<T>
  onSubmit: (values: T) => Promise<any>
}

export function useFormSchema<T extends object>({ schema, initialData, defaultValues = {}, onSubmit }: UseFormSchemaProps<T>) {
  const castInitialData = schema.cast(initialData)
  const fields = mapValues(schema.fields, (field, key) => useField({
      defaultValue: key in defaultValues ? defaultValues[key] : (castInitialData as any)[key],
      validations: [
        async x => {
          try {
            await field.validate(x)
            return []
          } catch (error) {
            return [error.message]
          }
        },
      ],
    }),
  )

  const form = useForm({
    fields: Object.values(fields),
    onSubmit: () => onSubmit(values)
  })

  useEffect(() => {
    form.validate()
  }, [])

  const values = mapValues(fields, f => f.props.value)

  return Object.assign(
    form,
    {
      fields,
      values,
    }
  )
}
