import { css } from '@emotion/core'
import { screens, ThemeColor, ThemeColors } from '../design-system'
import { string } from 'yup'

export const colors = {
  grey: {
    0: 'black',
    1: '#333333',
    2: '#777777',
    3: '#888888',
    4: '#EFEFEF',
    5: 'white',
  },
  pink: {
    0: '#FF80A1',
    1: '#FF91AD',
    2: '#FFB3C7',
  },
  green: {
    1: '#48CBB1',
  },
}

export const themeColor = {
  bg: colors.grey[5],
  bgAlt: colors.grey[4],
  content: 'black',
  bgReverse: colors.grey[1],
  reverseContent: 'white',
  contentAlt: colors.pink[0],
  subtleAlt: colors.pink[2],
  control: colors.green[1],
  altBg: 'rgba(255, 128, 161, 0.2)',
  link: colors.pink[0],
  linkActive: colors.pink[1],
}

export const themeFonts = {
  regular: css`
    font-family: NeueHaas;
    font-size: 18px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 26px;
    }
  `,
  small: css`
    font-family: NeueHaas;
    @media (min-width: ${screens.desktop}px) {
      font-size: 21px;
    }
  `,
  link: css`
    .active,
    :active {
      color: ${themeColor.link};
    }

    :hover:not(:active) {
      color: ${themeColor.linkActive};
      text-decoration: underline;
    }
  `,
  display: css`
    font-weight: 700;
    font-family: 'NeueHaas', sans-serif;
    line-height: 110%;

    font-size: 47px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 59px;
    }
  `,
  heading: css`
    font-family: 'NeueHaas', sans-serif;
    font-weight: 700;
    line-height: 110%;
    font-size: 51px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 85px;
    }
  `,
  franklin: css`
    font-family: 'FranklinGothic', sans-serif;
    font-weight: 400;
    line-height: 110%;
    font-size: 51px;
  `,
}

export const shadows = {
  up: `12px -12px ${colors.pink[2]}`,
}
