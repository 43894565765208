import { useToggle } from 'react-use'
import React, { ReactNode } from 'react'
import { JumpRow, useAccordion } from '../molecules'
import { ExpandContractGraphic } from '../graphics'
import { Box, Content, Columns, BoxProps, IconButton } from '../atoms'
import { event } from '../../services'

interface ContentAccordionProps extends BoxProps {
  label?: ReactNode
  analyticsEvent?: string
  rowBorder?: string
}

export const ContentAccordion = ({
  label,
  children,
  analyticsEvent,
  ...boxProps
}: ContentAccordionProps) => {
  const [open, toggleOpen] = useToggle(false)
  const accordion = useAccordion(open, { fade: true })

  const onClick = () => {
    if (!open && analyticsEvent) {
      event(analyticsEvent)
    }

    toggleOpen()
  }

  const hasContents = !!children

  return (
    <JumpRow
      {...boxProps}
      css={{
        fontSize: '32px',
        fontWeight: 'bold',
      }}
    >
      <IconButton width="100%" onClick={hasContents ? onClick : undefined}>
        <Columns justifyContent="space-between" alignItems="center">
          {/* <Content> */}
          <span
            css={{
              fontSize: '32px',
              fontWeight: 'bold',
              fontFamily: 'AA Gothic Bold',
            }}
          >
            {label}
          </span>
          {/* </Content> */}
          {hasContents && (
            <ExpandContractGraphic
              flexShrink={0}
              mode={open ? 'minus' : 'plus'}
            />
          )}
        </Columns>
      </IconButton>
      <Box overflow="hidden" style={accordion.spring}>
        <Box paddingVertical={2} ref={accordion.contentRef}>
          {children}
        </Box>
      </Box>
    </JumpRow>
  )
}
