import { useEffect, useState, useMemo, useRef } from 'react'

const BACKEND_URL = process.env.GATSBY_CMS_URL
const REST_AUTH_URL = `${BACKEND_URL}/rest-auth`
const STORAGE_KEY = 'twt.auth.user'

interface AuthState {
  token: string
  id: number
  registered: boolean
}

const API_HEADERS = {
  'content-type': 'application/json',
  accept: 'application/json',
}

export const getAuthHeaders = () => {
  if (typeof localStorage === 'undefined') {
    return {}
  }

  const state = localStorage.getItem(STORAGE_KEY)
  try {
    if (state) {
      const { token } = JSON.parse(state)
      return token ? { authorization: `Token ${token}` } : {}
    }
  } catch {}

  return {}
}

export const AuthStore = () => {
  const [auth, setAuthState] = useState<AuthState>()

  // workaround for not being able to rely on state in long-running operations
  const authRef = useRef(auth)

  /** Merge with existing state and persist */
  const setAuth = (state: Partial<AuthState> | undefined) => {
    if (!state) {
      localStorage.removeItem(STORAGE_KEY)
      authRef.current = undefined
      setAuthState(undefined)
    } else {
      const next = { ...authRef.current, ...state }
      localStorage.setItem(STORAGE_KEY, JSON.stringify(next))
      authRef.current = next
      setAuthState(next)
    }
  }

  const postLogin = async () => {
    // Check whether the logged in user has registered, save this information,
    // and return to caller

    const res = await fetch(REST_AUTH_URL + '/user/', {
      headers: {
        ...getAuthHeaders(),
        accept: 'application/json',
      },
    })

    if (!res.ok) {
      setAuth(undefined)
      return
    }

    const { is_registered, pk } = await res.json()
    setAuth({ registered: is_registered, id: pk })

    return { registered: is_registered as boolean }
  }

  useEffect(() => {
    try {
      setAuth(JSON.parse(localStorage.getItem(STORAGE_KEY)))
    } catch {}

    if (authRef.current) {
      // Check that the session is still valid
      postLogin()
    }

    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === STORAGE_KEY) {
        try {
          if (event.newValue) {
            setAuth(JSON.parse(event.newValue))
          } else {
            setAuth(undefined)
          }
        } catch {}
      }
    }

    window.addEventListener('storage', handleStorageEvent)
    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [])

  return useMemo(
    () => ({
      registered: Boolean(auth),
      userId: auth && auth.id,
      logOut: () => {
        setAuth(undefined)
        window.location.reload()
      },
      loginWithEmail: async (opts: { email: string }) => {
        // Just fire and forget. If the user already exists, this will fail, but either way we move straight
        // on to login with token
        await fetch(REST_AUTH_URL + '/registration/', {
          method: 'POST',
          headers: API_HEADERS,
          body: JSON.stringify({
            email: opts.email?.toLowerCase(),
          }),
        })

        await fetch(BACKEND_URL + '/auth/email/', {
          method: 'POST',
          headers: API_HEADERS,
          body: JSON.stringify({
            email: opts.email?.toLowerCase(),
          }),
        })
      },
      handleLoginWithPasswordlessToken: async ({ token, email }) => {
        const res = await fetch(BACKEND_URL + '/auth/token/', {
          method: 'POST',
          headers: API_HEADERS,
          body: JSON.stringify({ token, email: email?.toLowerCase() }),
        })

        if (!res.ok) {
          throw await res.json()
        }

        const data = await res.json()
        setAuth({
          token: data.token,
        })

        return postLogin()
      },
      handleLoginWithFacebook: async ({ accessToken }) => {
        const res = await fetch(REST_AUTH_URL + '/facebook/', {
          method: 'POST',
          headers: API_HEADERS,
          body: JSON.stringify({ access_token: accessToken }),
        })

        if (!res.ok) {
          throw await res.json()
        }

        const data = await res.json()
        setAuth({
          token: data.key,
        })

        return postLogin()
      },
    }),
    [auth, setAuth, postLogin],
  )
}
