import React from 'react'
import { SvgGraphic, SvgGraphicProps } from '../atoms'
import { Themed } from '../theme'
import { withTheme } from 'emotion-theming'

export interface LogoProps extends SvgGraphicProps {
  fill?: string
}

export const Logo = withTheme(
  ({
    theme,
    fill,
    scale = { mobile: 0.75, tablet: 1 },
    ...props
  }: Themed<LogoProps>) => (
    <SvgGraphic scale={scale} viewBox="0 0 99 63" fill="none" {...props}>
      <path
        d="M98.838 9.672l-8.183-4.804c-.065-.033-.162-.033-.227-.033a.241.241 0 0 0-.292 0L65.816 19.08l-7.955-4.605c-.065-.033-.097-.033-.162-.033a.24.24 0 0 0-.293 0l-7.045 4.145-.552.329-.747.428v17.04l-2.468-7.763c-.032-.099-.065-.165-.097-.23a1.127 1.127 0 0 0-.39-.395l2.24-1.316v-.79l-3.669 2.139v.033c-.065.065-.13.131-.195.23L41.561 32.7V14.87l7.468-4.376v7.994l.747-.427V9.9a.277.277 0 0 0-.13-.23L33.281.066c-.064-.033-.097-.033-.162-.033a.198.198 0 0 0-.227.033L.162 19.212c-.097.066-.162.165-.162.263v9.573c0 .1.065.198.13.264h.032l.033.033 7.793 4.572.552.33.292-.165.422-.263 7.11-4.145v27.601l-7.532-4.408V34.872l-.292.164-.358-.197V53.03c0 .099.033.198.13.23l.098.1 8.182 4.802c.097.066.195.066.26 0l8.215-4.737c.032-.033.064-.033.097-.066.032-.066.065-.098.065-.164V24.574l7.468-4.375v28.128c0 .099.065.197.13.263l8.117 4.803c.033 0 .033.033.065.033l.098.033c.065.033.13.066.227.033.032 0 .032 0 .065-.033l4.35-2.533-.227-.658-3.441 2.007 2.435-4.705a.457.457 0 0 1 .487-.263h.097a.408.408 0 0 1 .195.263l.39 1.02.13.362 3.312 9.014c.032.066.064.132.13.164l8.182 4.804c.032 0 .065.033.065.033l.097.065c.033.033.098.033.13.033s.097 0 .13-.033l8.182-4.803c.098-.066.13-.131.13-.23V39.773l-.325.198-.422-.263v17.995l-7.435 4.342V34.542l7.435 4.343.422.263.325-.197.357-.23 6.657-3.882.39-.23.779-.461 7.37-4.31v27.536l-7.37-4.31V34.871l-.78.461v17.666c0 .066.033.132.065.198.033.033.065.066.098.066l8.182 4.803c.032.033.097.033.162.033l.065.032a.241.241 0 0 0 .293 0l8.182-4.803c.097-.065.13-.131.13-.23V24.476l8.085-4.738c.097-.065.13-.131.13-.23V9.902c.032-.099-.033-.198-.13-.23zM41.204 14.31L33.249 9.64c-.032-.033-.098-.033-.13-.033a.445.445 0 0 0-.292 0L8.604 24.016.91 19.507 33.022.69l15.715 9.212-7.533 4.408zm37.665 6.711l-13.085 7.666L50.1 19.508l7.565-4.408 7.89 4.638c.065.033.163.066.228.033.064.033.162.033.26 0l24.32-14.245 7.467 4.376-18.962 11.12z"
        fill={theme.color(fill) || theme.colors.bg}
      />
    </SvgGraphic>
  )
)
