import { Box, BoxProps } from '../atoms'
import { useSpring } from 'react-spring'
import useOnClickOutside from 'use-onclickoutside'
import React, {
  useRef,
  useCallback,
  MouseEvent,
  useState,
  useMemo,
} from 'react'
import { noop } from 'lodash'
import { useScrollLock } from '../../services'
import { createPortal } from 'react-dom'

interface SideDrawerProps extends BoxProps {
  open?: boolean
  onClose?: () => void
}

const useBodyRef = () =>
  useMemo(() => {
    if (typeof document === 'undefined') {
      return undefined
    }

    let el = document.getElementById('foooter-pop')
    if (!el) {
      el = document.createElement('div')
      document.body.appendChild(el)
    }

    return el
  }, [])

export const SideDrawer = ({
  open = false,
  onClose = noop,
  children,
  ...boxProps
}: SideDrawerProps) => {
  const el = useBodyRef()
  const [hidden, setHidden] = useState(true)
  const spring = useSpring({
    position: 'fixed',
    top: 0,
    right: open ? '0' : '-100%',
    zIndex: 10000,
    onStart: () => {
      if (open) {
        setHidden(false)
      }
    },
    onRest: () => {
      if (!open) {
        setHidden(true)
      }
    },
  })

  const container = useRef<HTMLDivElement>(null)
  const handleClickInside = useCallback(
    (e: MouseEvent<{}>) => {
      const target = e.target as Element

      if (target.tagName.toLowerCase() === 'a') {
        onClose()
      }
    },
    [onClose]
  )

  useScrollLock(open)
  useOnClickOutside(container, onClose)

  if (!el) {
    return null
  }

  return createPortal(
    <Box
      ref={container}
      {...boxProps}
      style={{ ...spring, display: hidden ? 'none' : undefined }}
      onClick={handleClickInside}
    >
      {children}
    </Box>,
    el
  )
}
