import { ReactNode, RefObject, useState, useEffect, useMemo } from 'react'
import { throttle } from 'lodash'
import { tuple } from '../util'
import { useChildRefs } from './children'

interface ScrollSpyProps {
  initial?: HTMLElement
  refs: RefObject<HTMLElement>[]
  container: ContainerRef
  errorMargin?: number
}

type ContainerRef = RefObject<HTMLElement> | string

const useRefQuery = (q: ContainerRef) => {
  let [current, setCurrent] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setCurrent(
      typeof q === 'string'
        ? (document.querySelector(q) as HTMLElement) || undefined
        : q.current
    )
  }, [])

  return useMemo(() => ({ current }), [current])
}

export const useScrollSpy = ({
  refs,
  container,
  initial,
  errorMargin = 0,
}: ScrollSpyProps) => {
  const [active, setActive] = useState(initial)
  const containerRef = useRefQuery(container)

  const findCurrent = () =>
    refs.find(ref => {
      const item = ref.current
      const scrollView = containerRef.current

      if (!item || !scrollView) {
        return false
      }

      const { top, bottom } = item.getBoundingClientRect()

      // viewport is above item
      if (top > window.innerHeight - errorMargin) {
        return false
      }

      // viewport is below item
      if (bottom < 0 + errorMargin) {
        return false
      }

      return true
    })

  const cb = useMemo(
    () =>
      throttle(
        () => {
          const hit = findCurrent()

          setTimeout(() => {
            if (findCurrent() === hit) {
              setActive((hit && hit.current) || undefined)
            }
          }, 500)
        },
        100,
        { leading: true }
      ),
    [containerRef.current, setActive]
  )

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    window.addEventListener('scroll', cb)
    return () => {
      if (containerRef.current) {
        window.removeEventListener('scroll', cb)
      }
    }
  }, [containerRef.current])

  return active
}

interface ScrollSpyChildrenProps {
  children: ReactNode
  container: ContainerRef
}
export const useScrollSpyChildren = ({
  children,
  container,
}: ScrollSpyChildrenProps) => {
  const [refChildren, refs] = useChildRefs(children)
  const spyRef = useScrollSpy({
    refs,
    container,
  })

  const index = refs.findIndex(x => x.current === spyRef)

  return tuple(refChildren, spyRef, index)
}
