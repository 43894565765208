import React, { ReactNode } from 'react'
import { styled } from '../styled'
import { spacingLevel, screens } from '../layout'
import { overrideable } from '../theme'
import { css } from '@emotion/core'
import { HTMLAttributes } from 'react'
import {
  WhiteSpaceProperty,
  TextAlignProperty,
  TextOverflowProperty,
  OverflowProperty,
  WritingModeProperty,
} from 'csstype'
import { memoize } from 'lodash'
import { Base, BaseProps } from './responsive'

export interface TextProps extends BaseProps {
  variant?: string
  color?: string
  whitespace?: WhiteSpaceProperty
  children?: ReactNode
  align?: TextAlignProperty
  lineClamp?: number
  overflow?: OverflowProperty
  textOverflow?: TextOverflowProperty
  writingMode?: WritingModeProperty
  maxHeight?: string
}

export const Text = styled(Base.withComponent('span'))<TextProps>`
  white-space: ${({ whitespace }) => whitespace};
  ${({ variant = 'regular', theme }) => theme.font(variant)};
  ${({ color, theme }) => color && `color: ${theme.color(color)};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ writingMode }) => writingMode && `writing-mode: ${writingMode};`}
  ${({ lineClamp }) =>
    lineClamp &&
    `display: -webkit-box; line-clamp: ${lineClamp}; -webkit-line-clamp: ${lineClamp}; 
    -webkit-box-orient: vertical;`}
  ${({ textOverflow }) => textOverflow && `text-overflow: ${textOverflow};`}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
`

export const Content = overrideable(
  styled(Text.withComponent('div'))<TextProps>`
    line-height: 140%;
    max-width: 100%;

    p {
      margin-top: ${spacingLevel(2)}px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: ${spacingLevel(1)}px;
    }

    ul,
    ol,
    p:not(:last-child) {
      margin-bottom: ${spacingLevel(2)}px;

      @media (min-width: ${screens.desktop}px) {
        margin-bottom: ${spacingLevel(3)}px;
      }
    }

    li:not(:last-child) {
      margin-bottom: ${spacingLevel(1)}px;
    }

    a {
      color: ${({ theme }) => theme.colors.link};
    }

    overflow: hidden;
    text-overflow: ellipsis;
  `,
)

/** deprecated */
export const ContentSmall = styled(Content)(({ theme }) => theme.font('small'))

/** deprecated */
export const Caption = styled.div`
  ${({ theme }) => theme.font('regular')}
  font-size: 19px;
`

/** deprecated */
export const CaptionSmall = styled.div`
  ${({ theme }) => theme.font('regular')}
  font-size: 13px;

  @media (min-width: ${screens.desktop}px) {
    font-size: 13px;
  }

  ${({ theme }) => theme.font('control')}
`

interface DisplayProps extends HTMLAttributes<{}> {
  underline?: string
  size?: number
  color?: string
}

const headingReset = css`
  align-self: flex-start;
  margin-block-start: 0;
  margin-inline-start: 0;
  margin-block-end: 0;
  margin-inline-end: 0;
`

/** deprecated */
export const Display = styled.h2<DisplayProps>`
  ${({ theme }) => theme.font('display')}
  ${headingReset}

  border-bottom: ${({ underline }: DisplayProps) =>
    underline ? `8px solid ${underline}` : undefined};
`

/** deprecated */
export const AltDisplay = styled.h3`
  ${({ theme }) => theme.font('display')}
  color: ${({ theme }) => theme.colors.contentAlt};
  font-size: 27px;

  @media (min-width: ${screens.desktop}px) {
    font-size: 39px;
  }
`

interface HeadingProps extends TextProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  underline?: string
}

const getHeadingComponent = memoize(
  (level: HeadingProps['level']) =>
    styled(Text.withComponent(('h' + level) as keyof JSX.IntrinsicElements))<
      DisplayProps
    >`
      ${headingReset}
      border-bottom: ${({ underline, theme }) =>
        underline ? `8px solid ${theme.color(underline)}` : undefined};
    `,
)

export const Heading = ({
  level = 2,
  variant = 'heading',
  ...rest
}: HeadingProps) => {
  const Component = getHeadingComponent(level)
  return <Component variant={variant} {...rest} />
}

/** deprecated */
export const Subheading = styled.h3`
  font-family: 'NeueHaas', sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
`

/** deprecated */
export const Intro = styled.span`
  font-weight: 700;
`

export const Bold = styled.span`
  font-weight: 700;
`

export const Normal = styled.span`
  font-weight: 400;
`

export const Center = styled.span`
  text-align: center;
`

export const Underline = styled.span`
  text-decoration: underline;
`

export const Italic = styled.em`
  font-style: italic;
`

export const Quote = styled.blockquote`
  margin-left: 0;
  font-style: italic;
`

export const Paragraphs = (props: { text?: string }) =>
  (props.text && (
    <>
      {props.text.split('\n').map((text, i) => (
        <p key={i}>{text.trim()}</p>
      ))}
    </>
  )) ||
  null
