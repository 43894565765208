import React, { PropsWithChildren, useMemo } from 'react'
import { Provider } from 'outstated'
import { ThemeProvider } from 'emotion-theming'
import { ApolloClient, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { HttpLink } from '@apollo/client/link/http'
import { RetryLink } from '@apollo/client/link/retry'
import { InMemoryCache } from '@apollo/client/cache'

import { Theme, navMenuStore } from './design-system'
import { themeFonts, themeColor } from './twt-core/theme'
import { fontFamilies2019 } from './twt-2019/theme'
import { Global, css } from '@emotion/core'
import { extname } from 'path'
import { AuthStore, getAuthHeaders } from './services'
import { ApolloProvider } from '@apollo/react-hooks'

const PageContext = ({
  children,
  fetch,
}: PropsWithChildren<{ fetch: typeof window.fetch }>) => {
  const client = useMemo(() => {
    return new ApolloClient<{}>({
      link: ApolloLink.from([
        new RetryLink(),
        setContext((_, { headers }) => {
          return {
            headers: {
              ...headers,
              ...getAuthHeaders(),
            },
          }
        }),
        new HttpLink({
          uri: process.env.GATSBY_CMS_URL + '/graphql',
          fetch,
        }),
      ]),
      cache: new InMemoryCache(),
    })
  }, [fetch])

  return (
    <>
      {fontFamilies2019.map(({ name, decls }) =>
        decls.map(({ weight, src, style = 'normal' }) => (
          <Global
            key={src.join(',')}
            styles={css`
            @font-face {
              font-family: '${name}';
              src: ${src
                .map((u) => `url(${u}) format('${extname(u).substr(1)}')`)
                .join(', ')};
              font-weight: ${weight};
              font-style: ${style};
            }
          `}
          />
        )),
      )}
      <ApolloProvider client={client}>
        <ThemeProvider theme={new Theme(themeColor, themeFonts)}>
          <Provider stores={[navMenuStore, AuthStore]}>{children}</Provider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  )
}

export default PageContext
