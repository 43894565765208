import { styled } from '../styled'
import { Box, BoxProps } from '../atoms'
import {
  spacingLevel,
  makeResponsive,
  Responsive,
  Breakpoint,
  screens,
} from '../layout'
import {
  AlignContentProperty,
  JustifyItemsProperty,
  AlignItemsProperty,
  JustifyContentProperty,
} from 'csstype'

const px = (x: any) => (typeof x === 'number' ? `${x}px` : x)
const responsive = makeResponsive<GridProps>()

export interface GridProps extends BoxProps {
  gridFrom?: Breakpoint
  gap?: number
  rowGap?: number
  columnGap?: Responsive<number>
  columnSize?: Responsive<number | string>
  fillMode?: 'auto-fill' | 'auto-fit'
  maxColumnSize?: number | string
  justifyItems?: JustifyItemsProperty
  alignItems?: AlignItemsProperty
  justifyContent?: JustifyContentProperty
  alignContent?: AlignContentProperty
}

export const Grid = styled(Box)<GridProps>`
  ${responsive(
    'columnSize',
    (columnSize, { fillMode = 'auto-fit', maxColumnSize }) => `repeat(
      ${fillMode},
      minmax(
        ${px(columnSize)},
        ${maxColumnSize ? px(maxColumnSize) : '1fr'}
      )
    );`,
    'grid-template-columns',
  )}
  ${responsive(
    'columnGap',
    (margin) => margin && `${spacingLevel(margin)}px`,
    'column-gap',
  )};
  grid-gap: ${({ gap = 3 }) => spacingLevel(gap)}px;
  row-gap: ${({ rowGap }) => rowGap !== undefined && spacingLevel(rowGap)}px;
  justify-items: ${({ justifyItems = 'center' }) => justifyItems};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  align-content: ${({ alignContent = 'start' }) => alignContent};

  ${({ gridFrom, rowGap = 3 }) => {
    if (gridFrom) {
      return `
        @media (min-width: ${screens[gridFrom]}px) {
          display: grid;
        }
        @media (max-width: ${screens[gridFrom]}px) {
          > *:not(:last-child) {
            margin-bottom: ${spacingLevel(rowGap)}px;
          }
        }
      `
    }

    return 'display: grid;'
  }}
`
