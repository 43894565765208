import { FlexProps, Rows } from './flex'
import { styled } from '../styled'
import { Link } from './links'

export const Card = styled(Rows)<
  FlexProps & { variant?: string; borderColor?: string }
>`
  ${({ theme, variant }) => theme.getStyle('card', variant)}
`

export const LinkedCard = Card.withComponent(Link)
