import { useState, useEffect, useContext, createContext } from 'react'

const InjectedTime = createContext<number | undefined>(undefined)

export const useTime = (resolution = 1000) => {
  const [time, setTime] = useState(Date.now())

  const tick = () => {
    setTime(Date.now())
    setTimeout(tick, resolution)
  }

  useEffect(() => {
    setTimeout(tick, resolution)
  }, [])

  const injectedTime = useContext(InjectedTime)
  if (typeof injectedTime === 'number') {
    return injectedTime
  }

  return time
}

export const InjectTime = InjectedTime.Provider
