import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import React, { ComponentType, ReactNode, HTMLAttributes } from 'react'
import { styled } from '../styled'
import { css } from '@emotion/core'
import { Rows } from './flex'
import { Box } from './box'
import { parse } from 'url'

export const FakeLink = ({ to, ...props }: GatsbyLinkProps<{}>) => (
  <a href={to} onClick={(e) => e.preventDefault()} {...props} />
)

interface LinkProps {
  emph?: boolean
  preserveScroll?: boolean
  restoreScroll?: boolean
  children?: ReactNode
}

export const LinkExternal = styled(OutboundLink)<LinkProps>`
  font-family: 'NeueHaas', sans-serif;
  color: inherit;
  text-decoration: none;

  font-weight: ${({ emph }) => (emph ? 500 : 'inherit')};

  ${({ theme }) => css(theme.font('link'))}

  cursor: pointer;
`

interface EmailLinkProps extends LinkProps {
  address: string
}

export const EmailLink = ({ address, ...props }: EmailLinkProps) => (
  <LinkExternal href={`mailto:${address}`} {...props}>
    {address}
  </LinkExternal>
)

export const PhoneLink = ({ number, ...props }: EmailLinkProps) => (
  <LinkExternal href={`tel:${number}`} {...props}>
    {number}
  </LinkExternal>
)

export const BoxLinkExternal = styled(Box.withComponent(OutboundLink))`
  ${({ theme }) => css(theme.font('link'))}
  color: unset;
  text-decoration: unset;
`

const LinkInternal_ = LinkExternal.withComponent(
  GatsbyLink || FakeLink,
) as ComponentType<LinkProps & GatsbyLinkProps<{}>>

export const LinkInternal = (props: LinkProps & GatsbyLinkProps<{}>) => (
  <LinkInternal_
    {...props}
    state={{
      ...props.state,
      preserveScroll: props.preserveScroll,
      restoreScroll: props.restoreScroll,
    }}
    activeClassName="active"
  />
)

export const Link = ({
  to,
  ...rest
}: { to: string } & LinkProps & HTMLAttributes<{}>) => {
  if (!to) {
    return <Box {...rest} />
  }

  if (to.includes('://')) {
    if (parse(to).host === 'theworldtransformed.org') {
      return <LinkInternal to={parse(to).path} {...rest} />
    } else {
      return <LinkExternal target="_blank" href={to} {...rest} />
    }
  }
  if (to.includes('@')) {
    return <EmailLink address={to} {...rest} />
  }

  return <LinkInternal to={to} {...rest} />
}

export const BoxLinkInternal = Box.withComponent(LinkInternal)
export const BoxLink = Box.withComponent(Link)

export const NavLinks = styled.div`
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  transition: 0.1s;
`

export const NavLinksSmall = styled(NavLinks)`
  font-size: 16px;
  background: #333333 !important;
`

export const MobileNavLinks = styled(Rows)`
  font-size: 30px;
  font-weight: 700;
`
