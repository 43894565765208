import { styled } from '../styled'
import { Columns } from './flex'

export const DesktopMenu = styled(Columns)`
  align-items: center;
  font-size: 21px;

  > * {
    margin-left: 0.5em;

    :not(:first-child) {
      ::before {
        content: '|';
        margin-right: 0.5em;
        color: rgba(255, 255, 255, 0.25);
      }
    }

    ${({ theme }) => theme.getStyle('menuDivider')}
  }
`
