import { styled } from '../styled'
import { spacingLevel, Responsive, makeResponsive } from '../layout'
import { BoxProps, Box } from './box'
import { AlignItemsProperty, JustifyContentProperty } from 'csstype'
import { Base } from './responsive'

export interface FlexProps extends BoxProps {
  inline?: boolean
  noFlex?: boolean
  wrap?: boolean
  spacing?: Responsive<number>
  flipSpacing?: Responsive<number>
  alignItems?: Responsive<AlignItemsProperty>
  justifyContent?: Responsive<JustifyContentProperty>
  flip?: Responsive<boolean>
}

const responsive = makeResponsive<FlexProps>()

const FlexParent = styled(Box)<FlexProps>`
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'no-wrap')};
  display: ${({ inline, noFlex }) =>
    noFlex ? 'block' : inline ? 'inline-flex' : 'flex'};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  ${responsive(
    'justifyContent',
    justifyContent => justifyContent,
    'justify-content'
  )};

  ${responsive('alignItems', alignItems => alignItems, 'align-items')};
`

export const Columns = styled(FlexParent)`
  ${responsive('flip', flip => (flip ? 'column' : 'row'), 'flex-direction')};

  > *:not(:last-child) {
    ${responsive(
      'spacing',
      (spacing = 0) => spacingLevel(spacing) + 'px',
      'margin-right'
    )};
  }

  > * {
    ${responsive(
      'flipSpacing',
      (flipSpacing = 0) => spacingLevel(flipSpacing) + 'px',
      'margin-bottom'
    )};
  }
`

export const Rows = styled(FlexParent)`
  ${responsive('flip', flip => (flip ? 'row' : 'column'), 'flex-direction')};

  > *:not(:last-child) {
    ${responsive(
      'spacing',
      (spacing = 0) => spacingLevel(spacing) + 'px',
      'margin-bottom'
    )};
  }

  > * {
    ${responsive(
      'flipSpacing',
      (flipSpacing = 0) => spacingLevel(flipSpacing) + 'px',
      'margin-right'
    )};
  }
`

export const SepV = styled(Base.withComponent('div'))<{ offset?: number }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.content};
  opacity: 0.5;
  align-self: stretch;
  width: 1px;
  left: ${({ offset = 0 }) => spacingLevel(offset)}px;
`

export const SepH = styled(Base.withComponent('div'))<{ offset?: number }>`
  position: relative;
  background-color: rgba(0, 0, 0, 0.25);
  align-self: stretch;
  height: 1px;
  top: ${({ offset = 0 }) => spacingLevel(offset)}px;
`

export const Flex = styled(Base)`
  flex: 1;
`
