import { styled } from '../styled'
import { BoxProps, Box } from './box'
import React from 'react'
import { spacingLevel } from '../layout'

const ButtonAccessorySpacer = styled.div`
  display: inline-block;
  overflow: hidden;
  height: 0;
  margin-left: ${spacingLevel(2)}px;
`

const ButtonAccessoryContent = styled.div`
  position: absolute;
  margin-left ${spacingLevel(2)}px;
`

export const ButtonAccessory = ({ children, ...props }: BoxProps) => (
  <Box overflow="visible" {...props}>
    <ButtonAccessoryContent>{children}</ButtonAccessoryContent>
    <ButtonAccessorySpacer>{children}</ButtonAccessorySpacer>
  </Box>
)
