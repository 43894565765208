import { css } from '@emotion/core'
import { screens, Theme } from '../design-system'
import { DrukCondensed, NeueHaas } from '../assets/fonts'

export const colors = {
  purple: '#8B3D8D',
  green: '#009340',
  pink: '#FF2C63',
  yellow: '#EF8F05',
}

export const darkThemeColor = {
  ...colors,
  bg: '#FF2C63',
  bgAlt: '#8B3D8D',
  dark: '#333',
  content: 'white',
  contentAlt: 'white',
  link: 'white',
  linkActive: '#FF2C63',
}

export const lightThemeColor = {
  ...colors,
  bg: 'white',
  bgAlt: '#8B3D8D',
  dark: '#333',
  content: '#5F4A4F',
  contentAlt: '#FF2C63',
  link: '#FF2C63',
}

export const darkThemeFonts = {
  regular: css`
    font-family: NeueHaas;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;

    @media (min-width: ${screens.desktop}px) {
      font-size: 36px;
    }

    a {
      text-decoration: underline;
    }
  `,
  small: css`
    font-family: NeueHaas;
    font-weight: 300;
    font-size: 18px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 21px;
    }
  `,
  heading: css`
    font-family: NeueHaas;
    font-weight: 400;

    font-size: 26px;
    line-height: 34px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 42px;
      line-height: 49px;
    }
  `,
  headingSmall: css`
    font-family: NeueHaas;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;
  `,
  display: css`
    margin: 0;
    font-family: DrukCondensed;
    text-transform: uppercase;
    font-size: 195px;
    line-height: 0.8;
    letterspacing: 0.015em;

    @media (min-width: ${screens.tablet}px) {
      font-size: 500px;
      letter-spacing: 5;
    }
  `,
  link: css`
    .active,
    :active {
      opacity: 0.5;
    }

    &:hover:not(:active):not(.active) {
      opacity: 0.5;
    }
  `,
  panelCaption: css`
    font-family: NeueHaas;
    font-size: 13px;
    letter-spacing: 0.06em;
  `,
  panelTitle: css`
    font-family: NeueHaas;
    font-weight: 300;
    line-height: 131.1%;

    font-size: 24px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 30px;
    }
  `,
  panelBody: css`
    font-family: NeueHaas;
    letter-spacing: -0.03em;
    font-weight: 300;

    font-size: 14.1256px;
    line-height: 21px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 17px;
    }
  `,
  controlLabel: css`
    font-family: NeueHaas;
    font-size: 9px;
    line-height: 174.6%;
    font-weight: 400;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    @media (min-width: ${screens.tablet}px) {
      font-size: 13px;
      line-height: 23px;
    }
  `,
  controlContent: css`
    font-family: NeueHaas;
    font-size: 11px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: -0.05em;

    @media (min-width: ${screens.tablet}px) {
      font-size: 15.4px;
      line-height: 23px;
    }
  `,
  tableHeadingLarge: css`
    font-family: NeueHaas;
    font-size: 29px;
    line-height: 89.6%;
    font-weight: 500;
    letter-spacing: -0.12em;

    @media (min-width: ${screens.tablet}px) {
      font-size: 33px;
      line-height: 24px;
    }
  `,
  tableCaption: css`
    font-family: NeueHaas;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-size: 9px;
    line-height: 14px;

    @media (min-width: ${screens.tablet}px) {
      font-size: 9px;
      line-height: 13px;
    }
  `,
  tableItemHeadingLight: css`
    font-family: NeueHaas;
    font-weight: 300;
    letter-spacing: -0.05em;
    font-size: 12px;
    line-height: 123.6%;

    @media (min-width: ${screens.tablet}px) {
      font-size: 15.4px;
      line-height: 23px;
    }
  `,
  tableItemBody: css`
    font-family: NeueHaas;
    font-weight: 500;
    letter-spacing: -0.03em;
    font-size: 9px;
    line-height: 14px;

    @media (min-width: ${screens.tablet}px) {
      font-size: 12px;
      line-height: 18px;
    }
  `,
}

export const lightThemeFonts = {
  ...darkThemeFonts,
  regular: css`
    font-family: NeueHaas;
    font-weight: 300;
    letter-spacing: -0.03em;

    font-size: 19px;
    line-height: 29px;

    @media (min-width: ${screens.desktop}px) {
      font-size: 24px;
      line-height: 36px;
    }

    a {
      text-decoration: underline;
    }
  `,
}

export const lightTheme2019 = new Theme(lightThemeColor, lightThemeFonts)
export const darkTheme2019 = new Theme(darkThemeColor, darkThemeFonts)
export const fontFamilies2019 = [DrukCondensed, NeueHaas]
