/* eslint-disable react/prop-types */

import bugsnag from '@bugsnag/js'
import React from 'react'
import PageContext from './src/context'

if (process.env.GATSBY_BUGSNAG_API_KEY) {
  bugsnag(process.env.GATSBY_BUGSNAG_API_KEY)
}

export const wrapRootElement = ({ element }) => (
  <PageContext fetch={fetch}>{element}</PageContext>
)

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  // globalHistory._onTransitionComplete()
}

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.state && routerProps.location.state.preserveScroll) {
    return false
  }

  return true
}
