import React from 'react'
import { Themed } from '../theme'
import { Columns, SepV } from '../atoms'
import { Logo, LogoText, LogoProps } from '../graphics'
import { withTheme } from 'emotion-theming'

export const FullLogo = withTheme(
  ({ theme, scale, fill = 'content' }: Themed<LogoProps>) => (
    <Columns spacing={1} flexShrink={0}>
      <Logo scale={scale} fill={theme.color(fill)} />
      <SepV />
      <LogoText scale={scale} fill={theme.color(fill)} />
    </Columns>
  )
)
