import { Dictionary } from 'ts-essentials'
import { CSSObject, SerializedStyles, Interpolation } from '@emotion/css'
import {
  createContext,
  ComponentType,
  useContext,
  createElement,
  ReactElement,
  FC,
  isValidElement,
  cloneElement,
  forwardRef,
} from 'react'

export interface ThemeColors {
  bg: string
  bgAlt: string
  content: string
  contentAlt: string
  link: string
  linkActive: string
}

export interface ThemeFonts {
  display?: Styles
  heading?: Styles
  regular: Styles
  small: Styles
  link?: Styles
}

type Styles = CSSObject | SerializedStyles

export type ThemeColor = keyof ThemeColors
export type ThemeFont = keyof ThemeFonts

export class Theme {
  public static color(color: string) {
    return ({ theme }: any) => theme.color(color)
  }

  private overrides = new Map<ComponentType<any>, ComponentType<any>>()
  private styles: Record<string, Record<string, Interpolation>> = {}

  public constructor(readonly colors: ThemeColors, readonly fonts: ThemeFonts) {
    this.color = this.color.bind(this)
    this.font = this.font.bind(this)
    this.getStyle = this.getStyle.bind(this)

    this.colors = colors
    this.fonts = fonts
  }

  public clone() {
    const next = new Theme(
      Object.assign({}, this.colors),
      Object.assign({}, this.fonts),
    )
    next.overrides = new Map(this.overrides)
    next.styles = Object.assign({}, this.styles)

    return next
  }

  public color(color: string, ...defaults: string[]): string
  public color(color: string): string
  public color(color: string | undefined): string | undefined
  public color(
    color: string | undefined,
    ...defaults: string[]
  ): string | undefined {
    if (!color) {
      return undefined
    }

    if (defaults) {
      const hit = [color, ...defaults].find((x) => this.colors[color])
      return ((this.colors as unknown) as Dictionary<string>)[hit] || color
    }

    return ((this.colors as unknown) as Dictionary<string>)[color] || color
  }

  public font(font: string | ThemeFont): Styles
  public font(font: string | ThemeFont | undefined): Styles | undefined
  public font(font: ThemeFont | undefined): unknown {
    if (!font) {
      return undefined
    }

    return ((this.fonts as unknown) as Dictionary<string>)[font] || font
  }

  /** Override a component that has been marked as overrideable */
  public override<T>(
    base: OverridebleComponent<T>,
    override: ComponentType<T>,
  ): Theme {
    this.overrides.set(base, override)
    return this
  }

  public style(
    style: string,
    overrides: Record<string, Interpolation<any>>,
  ): Theme {
    this.styles[style] = overrides
    return this
  }

  public getStyle(style: string, variant: string = 'default'): Interpolation {
    const base = this.styles[style]
    if (!base) {
      return {}
    }

    return base[variant] || base.default || {}
  }

  public getOverride<T>(component: ComponentType<T>) {
    return this.overrides.get(component)
  }
}

export type Themed<T = {}> = T & { theme: Theme }
export type OverridebleComponent<T = {}> = ComponentType<T> & {
  Original: ComponentType<T>
}

/** Hack around the version of emotion we're using not having a 'useTheme' hook */
export const ThemeContext = createContext<Theme | undefined>(undefined)

/** Return the current theme */
export const useTheme = () => {
  const ctx = useContext(ThemeContext)
  if (!ctx) {
    throw Error('No theme context installed')
  }

  return ctx
}

/** Wrap an inner component and make it overrideable by a different component specified in the theme */
export const overrideable = <T>(
  Original: ComponentType<T>,
): OverridebleComponent<T> => {
  const wrappedComponent: FC<T> = forwardRef((props, ref) => {
    const theme = useTheme()
    const Override = theme.getOverride(wrappedComponent)

    return Override
      ? createElement(Override, { ref, ...props })
      : createElement(Original, { ref, ...props })
  })

  return Object.assign(wrappedComponent, { Original }, Original)
}

/** Inject component overrides into the context so that children */
export const AugmentTheme: FC<{
  overrides: {
    component: OverridebleComponent<any>
    use: ComponentType<any> | ReactElement<any>
  }[]
}> = ({ overrides, children }) => {
  const theme = useTheme().clone()

  overrides.forEach(({ component: token, use: impl }) => {
    if (isValidElement(impl)) {
      theme.override(token, (props) => cloneElement(impl, props))
    } else {
      theme.override(token, impl)
    }
  })

  return createElement(ThemeContext.Provider, { value: theme, children })
}
