// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-preview-tsx": () => import("./../src/preview.tsx" /* webpackChunkName: "component---src-preview-tsx" */),
  "component---src-twt-core-pages-default-page-tsx": () => import("./../src/twt-core/pages/default-page.tsx" /* webpackChunkName: "component---src-twt-core-pages-default-page-tsx" */),
  "component---src-festival-pages-event-page-tsx": () => import("./../src/festival/pages/event-page.tsx" /* webpackChunkName: "component---src-festival-pages-event-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-authenticate-tsx": () => import("./../src/pages/authenticate.tsx" /* webpackChunkName: "component---src-pages-authenticate-tsx" */),
  "component---src-pages-login-tsx": () => import("./../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-twt-tsx": () => import("./../src/pages/my-twt.tsx" /* webpackChunkName: "component---src-pages-my-twt-tsx" */),
  "component---src-pages-register-tsx": () => import("./../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-ticket-site-tsx": () => import("./../src/pages/ticket-site.tsx" /* webpackChunkName: "component---src-pages-ticket-site-tsx" */)
}

