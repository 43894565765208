import React, { Children } from 'react'
import { Box, BoxProps, Columns } from '../atoms'
import { spacingLevel } from '../layout'
import { styled } from '../styled'

interface JumpRowProps extends BoxProps {
  rowBorder?: string
}

export const JumpRow = styled(Box)<JumpRowProps>`
  /* border-top: ${({ rowBorder, color = 'content', theme }) =>
    rowBorder || `2px solid ${theme.color(color)}`}; */
  padding: 12px 24px;
  background-color: #95cce3;

  &:last-child {
    border-bottom: ${({ rowBorder, color = 'content', theme }) =>
      rowBorder || `2px solid ${theme.color(color)}`};
  }

  &:last-of-type {
    margin-bottom: '24px';
  }
`
export const JumpGrid = ({ children, ...props }: BoxProps) => (
  <>
    <Box showUntil="desktop" width="100%" {...props}>
      {children}
    </Box>
    <Columns width="100%" showFrom="desktop" spacing={3} {...props}>
      {Children.map(children, (x) => (
        <Box width="50%">{x}</Box>
      ))}
    </Columns>
  </>
)
