/** @jsx jsx */

import { Box, BoxProps } from './box'
import { styled } from '../styled'
import { jsx } from '@emotion/core'
import { FC } from 'react'

export const LoadingSpinner: FC<BoxProps> = (props) => (
  <SpinnerWrapper {...props}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </SpinnerWrapper>
)

export const LoadingPlaceholder: FC<BoxProps> = ({ ...props }) => (
  <Box height="30em" maxHeight="80vh" {...props}>
    <LoadingSpinner
      css={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  </Box>
)

export const LoadingOverlay: FC<BoxProps & { loading: boolean }> = ({
  loading,
  ...props
}) => (
  <Box
    css={{ transition: 'opacity 200ms ease-in-out', opacity: loading ? 1 : 0 }}
    position="absolute"
    width="100%"
    height="100%"
    {...props}
  >
    <LoadingSpinner
      css={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  </Box>
)

export const SpinnerWrapper = styled(Box)`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme, color = 'contentAlt' }) => theme.color(color)}
      transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
