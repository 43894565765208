import React from 'react'
import { useSpring, animated } from 'react-spring'
import { SvgGraphicProps, SvgGraphic } from '../../design-system'

interface ExpandContractGraphicProps extends SvgGraphicProps {
  mode?: 'plus' | 'minus'
  thickness?: number
  color?: string
}

export const ExpandContractGraphic = ({
  mode = 'plus',
  thickness = 4,
  color = 'currentColor',
  ...svgProps
}: ExpandContractGraphicProps) => {
  const spring = useSpring({
    dashOffset: mode === 'plus' ? 0 : 120,
  })

  return (
    <SvgGraphic viewBox="0 0 47 45" fill="none" scale={0.36} {...svgProps}>
      <animated.path
        d="M23.192 0v44.983"
        stroke={color}
        strokeWidth={2.5 * thickness}
        strokeDasharray={100}
        strokeDashoffset={spring.dashOffset}
        strokeMiterlimit={10}
      />
      <path
        d="M0 22.492h46.384"
        stroke={color}
        strokeWidth={2.5 * thickness}
        strokeMiterlimit={10}
      />
    </SvgGraphic>
  )
}
