import React, { ReactNode, memo } from 'react'

import { Global } from '@emotion/core'
import Helmet from 'react-helmet'
import css from '@emotion/css'
import { ThemeProvider } from 'emotion-theming'

import { Theme, ThemeContext } from '../theme'
import { extname } from 'path'

export interface PageProps {
  title?: string
  titlePrefix?: string
  description: string
  splashImage: string
  fonts?: FontFamilyDecl[]
  children?: ReactNode
  theme: Theme
}

interface FontDecl {
  weight: number
  src: string[]
  style?: string
}

interface FontFamilyDecl {
  name: string
  decls: FontDecl[]
}

const isStorybook = () => typeof window !== 'undefined' && 'STORYBOOK' in window

export const Page = memo(
  ({
    title,
    titlePrefix = 'The World Transformed',
    description,
    splashImage,
    theme,
    fonts = [],
    children,
  }: PageProps) => {
    const fullTitle = title ? `${titlePrefix} // ${title}` : titlePrefix
    return (
      <>
        <Helmet title={fullTitle}>
          <meta name="description" content={description} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="TWT_NOW" />
          <meta property="og:title" content={fullTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={splashImage} />
          <meta property="og:url" content="https://theworldtransformed.org/" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="The World Transformed" />
          <meta name="referrer" content="origin" />
          <link
            href="//webfonts2.radimpesko.com/RP-W-610accc56f3e0c34ca00000b.css"
            rel="stylesheet"
            type="text/css"
            referrerPolicy="origin"
          />
        </Helmet>
        {fonts.map(({ name, decls }) =>
          decls.map(({ weight, src, style = 'normal' }) => (
            <Global
              key={src.join(',')}
              styles={css`
              @font-face {
                font-family: '${name}';
                src: ${src
                  .map(
                    (u) =>
                      `url(${encodeURI(u)}) format('${extname(u).substr(1)}')`,
                  )
                  .join(', ')};
                font-weight: ${weight};
                font-style: ${style};
              }
            `}
            />
          )),
        )}
        <Global
          styles={css`
            html {
              background-color: ${theme.colors.bg};
              color: ${theme.colors.content};
              position: relative;
            }

            body {
              margin: 0;
              width: 100%;
            }

            html,
            div,
            body {
              touch-action: manipulation;
            }

            html,
            body,
            #___gatsby,
            #___gatsby > *,
            #root {
              min-height: 100%;
              width: 100%;
              position: relative;
              height: ${isStorybook() ? 'auto' : '100%'};
            }

            #___gatsby > * {
              perspective: 2px;
              perspective-origin: 0 0;
              -webkit-overflow-scrolling: touch;
              height: 100%;
            }

            * {
              -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            }
          `}
        />
        <ThemeContext.Provider value={theme}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
      </>
    )
  },
)
