import { BoxProps, Box } from '../atoms'
import styled from '@emotion/styled'
import { Responsive, makeResponsive } from '../layout'

interface TimelineItemProps extends BoxProps {
  scale?: Responsive<number>
  offset?: number
  size?: number
}

const responsive = makeResponsive<TimelineItemProps>()

export const TimelineColumn = styled(Box)<TimelineItemProps>`
  position: absolute;
  height: 100%;

  ${responsive('scale', (scale, { offset }) => scale * offset + 'px', 'left')};
  ${responsive('scale', (scale, { size }) => scale * size + 'px', 'width')};
`

export const TimelineColumns = styled(Box)<TimelineItemProps>`
  ${responsive('scale', (scale, { size }) => scale * size + 'px', 'width')};
`
