import { styled } from '../styled'
import { Box } from './box'

export const Backlay = styled(Box)`
  > *:not(:last-child) {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  > *:last-child {
    box-sizing: content-box;
    z-index: 1;
  }
`
