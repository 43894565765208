import { styled } from '../styled'
import { Box, BoxProps } from './box'

export const Gutter = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    max-width: 80em;
  }
`

// https://css-tricks.com/aspect-ratio-boxes/
export const AspectRatioBox = styled('div')<BoxProps & { ratio: number }>`
  height: 0;
  overflow: hidden;
  padding-top: calc(${({ ratio }) => ratio} * 100%);
  background: white;
  position: relative;
  box-sizing: border-box;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Centered = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
