export const NeueHaas = {
  name: 'NeueHaas',
  decls: [
    {
      weight: 700,
      src: [
        require('./NeueHaasUnicaW1G-Bold.woff2'),
        require('./NeueHaasUnicaW1G-Bold.ttf'),
      ],
    },
    {
      weight: 500,
      src: [
        require('./NeueHaasUnicaW1G-Medium.woff2'),
        require('./NeueHaasUnicaW1G-Medium.ttf'),
      ],
    },
    {
      weight: 400,
      src: [
        require('./NeueHaasUnicaW1G-Regular.woff2'),
        require('./NeueHaasUnicaW1G-Regular.ttf'),
      ],
    },
    {
      weight: 300,
      src: [
        require('./NeueHaasUnicaW1G-Light.woff2'),
        require('./NeueHaasUnicaW1G-Light.ttf'),
      ],
    },
    {
      weight: 700,
      style: 'italic',
      src: [
        require('./NeueHaasUnicaW1G-BoldItalic.woff2'),
        require('./NeueHaasUnicaW1G-BoldItalic.ttf'),
      ],
    },
    {
      weight: 500,
      style: 'italic',
      src: [
        require('./NeueHaasUnicaW1G-MediumItalic.woff2'),
        require('./NeueHaasUnicaW1G-MediumItalic.ttf'),
      ],
    },
    {
      weight: 400,
      style: 'italic',
      src: [
        require('./NeueHaasUnicaW1G-RegularItalic.woff2'),
        require('./NeueHaasUnicaW1G-RegularItalic.ttf'),
      ],
    },
    {
      weight: 300,
      style: 'italic',
      src: [
        require('./NeueHaasUnicaW1G-LightItalic.woff2'),
        require('./NeueHaasUnicaW1G-LightItalic.ttf'),
      ],
    },
  ],
}

export const DrukCondensed = {
  name: 'DrukCondensed',
  decls: [
    {
      weight: 700,
      src: [require('./DrukCondXXSuper.woff2')],
    },
  ],
}

export const Goudy = {
  name: 'Goudy',
  decls: [
    {
      weight: 700,
      src: [require('./Goudy Old Style - Extra Bold.woff')],
    },
  ],
}

export const FranklinGothic = {
  name: 'FranklinGothic',
  decls: [
    {
      weight: 400,
      src: [require('./FranklinGothic-Book.ttf')],
    },
  ],
}
