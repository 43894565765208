import { useApolloClient } from '@apollo/react-hooks'
import { useCallback } from 'react'
import { noop, mapValues } from 'lodash'
import { useField, useForm, Field } from 'react-jeff'

interface GraphQlMutationFormProps<Data, Res> {
  mutation: string
  name?: string
  value: Data
  onCompleted?: (res: Res) => void
  defaultError?: string
}

export const useGraphQlForm = <Data, Res>({
  mutation,
  value,
  onCompleted = noop,
}: GraphQlMutationFormProps<Data, Res>) => {
  const client = useApolloClient()
  const fields = mapValues((value as unknown) as object, (x: unknown) =>
    useField({ defaultValue: x }),
  ) as { [P in keyof Data]: Field<Data[P]> }

  const onSubmit = useCallback(async () => {
    try {
      const res = await client.mutate({
        mutation,
        variables: {
          input: mapValues(fields, f => f.value),
        },
      })

      console.log('completed')

      onCompleted(res)
    } catch (error) {
      return
    }
  }, [client, mutation, fields, onCompleted])

  const form = useForm({
    fields: Object.values(fields),
    onSubmit,
  })

  return {
    form,
    fields,
  }
}
