import { useState } from 'react'
import { useSpring } from 'react-spring'

export const useAccordion = (
  open: boolean,
  opts: { minHeight?: string; fade?: boolean } = {}
) => {
  const [boxRef, setBoxRef] = useState<HTMLDivElement | null>()
  const { minHeight = '0px', fade } = opts

  const maxHeight = () => {
    if (!boxRef) {
      return minHeight
    }

    const { height } = boxRef.getBoundingClientRect()
    return height + 'px'
  }

  const spring = useSpring({
    opacity: fade ? (open ? 1 : 0) : 1,
    maxHeight: open ? maxHeight() : minHeight,
  })

  return { contentRef: setBoxRef, spring }
}
