import React, { ReactNode } from 'react'
import {
  DesktopMenu,
  Rows,
  Content,
  LinkExternal,
  Flex,
  Columns,
  EmailLink,
  IconButton,
} from '../atoms'
import { useToggle } from 'react-use'
import { useStore } from 'outstated'
import { TwitterIcon, FacebookIcon, BurgerGraphic } from '../graphics'
import { SideDrawer } from '../molecules'

export const navMenuStore = () => {
  const [visible, toggleVisible] = useToggle(false)
  return {
    visible,
    toggleVisible,
    show: () => toggleVisible(true),
    hide: () => toggleVisible(false),
  }
}

interface NavMenuProps {
  children?: ReactNode
  showSeparators?: boolean
}

export const NavMenu = ({ children }: NavMenuProps) => {
  const menu = useStore(navMenuStore)

  return (
    <>
      <DesktopMenu showFrom="desktop">{children}</DesktopMenu>

      <SideDrawer open={menu.visible} onClose={menu.hide}>
        <Rows
          width="320px"
          height="100vh"
          padding={2}
          color="content"
          bg="bg"
          shadow={`-2px -2px 2px rgba(0,0,0,0.3)`}
        >
          <Content>
            <Rows spacing={2}>{children}</Rows>
          </Content>
          <Flex />
          <Columns wrap spacing={2} alignItems="center">
            <LinkExternal href="https://twitter.com/TWT_NOW">
              <TwitterIcon />
            </LinkExternal>
            <LinkExternal href="https://www.facebook.com/TWTNow/">
              <FacebookIcon />
            </LinkExternal>
            <Flex />
            <EmailLink address="info@theworldtransformed.org" />
          </Columns>
        </Rows>
      </SideDrawer>

      <IconButton onClick={menu.toggleVisible} showUntil="desktop">
        <BurgerGraphic fill="content" />
      </IconButton>
    </>
  )
}
