import React, { ImgHTMLAttributes, ComponentType, FC } from 'react'
import { styled } from '../styled'
import { Box, BoxProps } from './box'
import GatsbyImage, { GatsbyImageFluidProps } from 'gatsby-image'

export interface Image extends BoxProps {
  url?: string
  objectFit?: string
  objectPosition?: string
}

export interface ImageViewProps
  extends BoxProps,
    Omit<ImgHTMLAttributes<{}>, keyof BoxProps> {
  objectFit?: string
  objectPosition?: string
  filter?: string
}

export const ImageView = styled(Box.withComponent('img'))`
  object-fit: ${({ objectFit = 'contain' }: ImageViewProps) => objectFit};
` as ComponentType<ImageViewProps>

export const image = ({ url, ...srcProps }: Image) =>
  Object.assign(
    function ImageInstance(props: ImageViewProps) {
      return <ImageView src={url} {...srcProps} {...props} />
    },
    { url, ...srcProps },
  )

export const ResponsiveImage: FC<
  BoxProps & { image: { srcSet: any } } & Omit<GatsbyImageFluidProps, 'fluid'>
> = ({ image, imgStyle, children, ...props }) => (
  <Box width="100%" {...props}>
    <GatsbyImage
      fluid={image as any}
      imgStyle={imgStyle}
      style={{ width: '100%', height: '100%' }}
    />
    {children && (
      <Box position="absolute" left={0} top={0} width="100%" height="100%">
        {children}
      </Box>
    )}
  </Box>
)
